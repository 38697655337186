import { ref, computed } from 'vue';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';

const LS_KEY = 'cdm-locale-preference';
const FALLBACK_LOCALE = 'en-US';
export const SYNC_WITH_BROWSER_VALUE = 'BROWSER';

// ブラウザで設定されている locale + en を出力
export const getLocaleOptions = (
  options: { syncWithBrowser?: boolean } = {},
): { value: string; label: string }[] => {
  const languages = new Intl.DisplayNames(['en'], { type: 'language', style: 'narrow' });
  const locales = [...navigator.languages];
  if (locales.length === 0) {
    locales.push(navigator.language);
  }
  if (!locales.includes('en')) {
    // 英語は常に選択肢に表示する
    locales.push('en');
  }
  if (!locales.includes(locale.value)) {
    // 設定済の値がリストにない場合は追加する
    locales.push(locale.value);
  }
  const localeOptions = locales.map(locale => ({
    value: locale,
    label: languages.of(locale) || locale,
  }));
  if (options.syncWithBrowser) {
    localeOptions.push({
      value: SYNC_WITH_BROWSER_VALUE,
      label: 'Sync with browser',
    });
  }
  return localeOptions;
};

export const getLocalePreference = (): string => {
  let locale = AccountStore.getPreference('locale');
  if (!locale) {
    locale = window.localStorage.getItem(LS_KEY) as string;
  }
  if (!locale || locale === SYNC_WITH_BROWSER_VALUE) {
    locale = navigator.language || FALLBACK_LOCALE;
  }
  return locale;
};

export const setLocalePreference = async (locale: string): Promise<void> => {
  if (AccountStore.isInitialized()) {
    await AccountStore.updatePreference('locale', locale);
  }
  window.localStorage.setItem(LS_KEY, locale);
  window.location.reload();
};

export const syncLocalePreference = () => {
  const l = getLocalePreference();
  window.localStorage.setItem(LS_KEY, l);
  locale.value = l;
};

// この設定に対して reactive にする
export const locale = ref(getLocalePreference());

export const isJapanese = computed(() => {
  return locale.value.startsWith('ja');
});
