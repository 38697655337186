<script setup lang="ts">
import { getAssetUrl } from '@cdm/utils/asset';
import LogoBlack from '@cdm/assets/images/logo_black.svg';
import LogoWhite from '@cdm/assets/images/logo_white.svg';
import { isDark } from '@cdm/domains/account/shared/hooks/useThemePreference';

const props = defineProps<{
  size: number;
}>();
</script>

<template>
  <div class="Logo">
    <img v-if="isDark" :src="getAssetUrl(LogoWhite)" alt="Codatum" :width="props.size" />
    <img v-else :src="getAssetUrl(LogoBlack)" alt="Codatum" :width="props.size" />
  </div>
</template>

<style scoped>
.Logo {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 36px;
  top: 30px;
  z-index: 1;
}

.Logo :deep(a) {
  display: block;
}

.Logo :deep(img) {
  display: block;
}

@media only screen and (max-width: 599px) {
  .Logo {
    left: 24px;
    top: 24px;
  }

  .Logo :deep(img) {
    width: 110px;
  }
}
</style>
