const useFirebaseAuthEmulator = (globalThis as any).__USE_FIREBASE_AUTH_EMULATOR__ ?? true;

const BASE = {
  ENVIRONMENT: 'development',
  ACCOUNT_URL: 'https://api.cdm.test',
  NOTEBOOK_URL: 'https://api.cdm.test/notebook',
  NOTEBOOK_WS_URL: 'wss://api.cdm.test/notebook/ws',
  PUBLISHED_FILES_URL: 'https://published-files.dev-cdm.com',
  API_ENDPOINT_BASE: 'https://api.cdm.test/api',
  CONTENTFUL_API_ENDPOINT_BASE: 'https://contentful-api-staging.codatum.workers.dev/api',

  // auth関連
  ENABLE_FIREBASE_AUTH_EMULATOR: useFirebaseAuthEmulator === true,
  FIREBASE_AUTH_EMULATOR_HOST: 'https://auth.cdm.test',
  IDENTITY_PLATFORM_API_KEY: 'AIzaSyAO3Os60omTZy7gR80qpxB-iETiEz9brcg',
  IDENTITY_PLATFORM_AUTH_DOMAIN: 'development-codatum.firebaseapp.com',

  SENTRY_DSN: '',
  // organization: codatum
  // SENTRY_DSN: 'https://301c77d0c10541a89009797b2b9e38a5@o4507564144721920.ingest.us.sentry.io/4504964871094272',
  // organization: plaid
  // SENTRY_DSN: "https://301c77d0c10541a89009797b2b9e38a5@o108042.ingest.sentry.io/4504964871094272",

  KRT_EDGE_TAG: '',
  // KRT_EDGE_TAG: 'https://cdn-edge.karte.io/9d27f37bc60e23f86fef52429580abf0/edge.js',

  SEGMENT_WRITE_KEY: '',
  // SEGMENT_WRITE_KEY: 'KH22fbDJPoEevFlNFfhvPWkQ2TY3zKZs',

  GOOGLE_ANALYTICS_ID: '',
  // GOOGLE_ANALYTICS_ID: 'G-4HFNV2VRFR',
  DATADOG_SITE: 'us5.datadoghq.com',
  DATADOG_APPLICATION_ID: '',
  DATADOG_CLIENT_TOKEN: '',
  DATADOG_SESSION_SAMPLE_RATE: 100,
  DATADOG_SESSION_REPLAY_SAMPLE_RATE: 0,

  CANNY_APP_ID: '6649f2ba5235b24ae84c3c2a',
  // https://developers.cloudflare.com/turnstile/troubleshooting/testing/
  CLOUDFLARE_TURNSTILE_SITE_KEY: '1x00000000000000000000AA',
};

export type GlobalConfigType = typeof BASE;

const EVALUATION: Partial<GlobalConfigType> = {
  ENVIRONMENT: 'evaluation',
  ACCOUNT_URL: 'https://api.evacdm.com',
  NOTEBOOK_URL: 'https://api.evacdm.com/notebook',
  NOTEBOOK_WS_URL: 'wss://api.evacdm.com/notebook/ws',
  PUBLISHED_FILES_URL: 'https://published-files.evacdm.com',
  API_ENDPOINT_BASE: 'https://api.evacdm.com/api',

  ENABLE_FIREBASE_AUTH_EMULATOR: false,
  IDENTITY_PLATFORM_API_KEY: 'AIzaSyBnSHazDyZvBy3-6z_8CwT3xfkYr3fCV8o',
  IDENTITY_PLATFORM_AUTH_DOMAIN: 'auth.app.evacdm.com',

  SENTRY_DSN: 'https://301c77d0c10541a89009797b2b9e38a5@o108042.ingest.sentry.io/4504964871094272',

  KRT_EDGE_TAG: '',
  SEGMENT_WRITE_KEY: '',
  GOOGLE_ANALYTICS_ID: '',
  DATADOG_APPLICATION_ID: '',
  DATADOG_CLIENT_TOKEN: '',
  CLOUDFLARE_TURNSTILE_SITE_KEY: '0x4AAAAAAAfZHmxdBMq3XEYX',
};

const PRODUCTION: Partial<GlobalConfigType> = {
  ENVIRONMENT: 'production',
  ACCOUNT_URL: 'https://api.codatum.com',
  NOTEBOOK_URL: 'https://api.codatum.com/notebook',
  NOTEBOOK_WS_URL: 'wss://api.codatum.com/notebook/ws',
  PUBLISHED_FILES_URL: 'https://published-files.codatum.com',
  API_ENDPOINT_BASE: 'https://api.codatum.com/api',
  CONTENTFUL_API_ENDPOINT_BASE: 'https://contentful-api.codatum.com/api',

  ENABLE_FIREBASE_AUTH_EMULATOR: false,
  IDENTITY_PLATFORM_API_KEY: 'AIzaSyCojHinKrU_OwWv7i9p75vPMasFPP1Inis',
  IDENTITY_PLATFORM_AUTH_DOMAIN: 'auth.app.codatum.com',

  SENTRY_DSN: 'https://301c77d0c10541a89009797b2b9e38a5@o108042.ingest.sentry.io/4504964871094272',

  KRT_EDGE_TAG: 'https://cdn-edge.karte.io/9d27f37bc60e23f86fef52429580abf0/edge.js',
  SEGMENT_WRITE_KEY: 'KH22fbDJPoEevFlNFfhvPWkQ2TY3zKZs',
  GOOGLE_ANALYTICS_ID: 'G-4HFNV2VRFR',
  DATADOG_APPLICATION_ID: 'dc75df77-69a9-43f1-b656-71770694845a',
  DATADOG_CLIENT_TOKEN: 'pub98fe4ae5611a3ae31be367fb0a7d9f50',
  CLOUDFLARE_TURNSTILE_SITE_KEY: '0x4AAAAAAAfZH3CA7HgboLMq',
};

export const GlobalConfig = (function (): GlobalConfigType {
  // https://vitejs.dev/guide/env-and-mode.html こちらを使わずwindowから渡す形式にしているのは、buildを分ける必要があるため
  // !!configの内容は、developmentのものもproductionに露出するので秘匿性の高いものは入れない（というかそもそもfrontで使わない）!!
  const mode = window.__VITE_META_ENV_MODE__;

  let config = BASE;

  if (mode == 'production') {
    config = Object.assign(config, PRODUCTION);
  } else if (mode == 'evaluation') {
    config = Object.assign(config, EVALUATION);
  }

  return config;
})();
