<script setup lang="ts">
import { computed, onBeforeMount, watch, ref } from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import { useLocale } from '@arco-design/web-vue';
import { isDark } from '@cdm/domains/account/shared/hooks/useThemePreference';
import { locale, isJapanese } from '@cdm/domains/account/shared/hooks/useLocalePreference';
import ErrorBoundary from '@cdm/components/error-boundary/ErrorBoundary.vue';
import UnknownError from '@cdm/pages/UnknownError.vue';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';
import { initializeFirebaseUser } from '@cdm/libs/auth';
import { colorPaletteStore } from '@cdm/libs/color-palette';

const getRenderKey = () => `${isDark.value ? 'dark' : 'light'}-${locale.value}`;
const renderKey = ref(getRenderKey());

watch(
  isDark,
  v => {
    if (v) {
      document.body.setAttribute('color-scheme', 'dark');
      colorPaletteStore.setTheme('DARK');
    } else {
      document.body.removeAttribute('color-scheme');
      colorPaletteStore.setTheme('LIGHT');
    }
    // テーマが変更されたら再レンダリング
    renderKey.value = getRenderKey();
  },
  { immediate: true },
);
watch(
  locale,
  () => {
    document.documentElement.lang = isJapanese.value ? 'ja' : 'en';
    useLocale(isJapanese.value ? 'jaJP' : 'enUS');
    renderKey.value = getRenderKey();
  },
  { immediate: true },
);

// ページタイトルの設定
const route = useRoute();
const workspace = computed(() => AccountStore.getWorkspace());

const title = computed(() => {
  const titleTerms: string[] = [];

  if (route.meta.title) {
    titleTerms.push(route.meta.title as string);
  }

  if (workspace.value) {
    titleTerms.push(workspace.value.name);
  }

  if (titleTerms.length <= 1) {
    titleTerms.push('Codatum');
  }

  return titleTerms.join(' - ');
});

useHead({
  title,
});

// firebase userのwatch
// sessionが復帰した場合は自動でsession内の画面に遷移する処理もあるので、session外の画面でもwatchする
onBeforeMount(() => {
  initializeFirebaseUser();
});
</script>

<template>
  <ErrorBoundary :key="renderKey">
    <router-view></router-view>
    <template #error="{ error }">
      <UnknownError :error="error" />
    </template>
  </ErrorBoundary>
</template>

<style>
/* 次の問題のため charset を指定 https://github.com/codatum/codatum/issues/3466 */
@charset "utf-8";

.firebase-emulator-warning {
  visibility: hidden;
}
</style>
