import { ref } from 'vue';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';

const LS_KEY = 'cdm-theme-preference';

export const THEME_PREFERENCES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  SYSTEM: 'SYSTEM',
} as const;

export type ThemePreference = typeof THEME_PREFERENCES[keyof typeof THEME_PREFERENCES];

export const THEME_PREFERENCE_OPTIONS: { label: string; value: ThemePreference }[] = [
  { label: 'Light theme', value: THEME_PREFERENCES.LIGHT },
  { label: 'Dark theme', value: THEME_PREFERENCES.DARK },
  { label: 'Sync with system', value: THEME_PREFERENCES.SYSTEM },
];

export const getThemePreference = (): ThemePreference => {
  const pref = AccountStore.getPreference('theme');
  if (pref) return pref;
  return (window.localStorage.getItem(LS_KEY) as ThemePreference) || THEME_PREFERENCES.SYSTEM;
};

export const setThemePreference = async (t: ThemePreference): Promise<void> => {
  if (AccountStore.isInitialized()) {
    await AccountStore.updatePreference('theme', t);
  }
  window.localStorage.setItem(LS_KEY, t);
  isDark.value = checkIsDark(t);
};

const checkIsDark = (t: ThemePreference): boolean => {
  if (t === THEME_PREFERENCES.SYSTEM) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches === true;
  } else if (t === THEME_PREFERENCES.DARK) {
    return true;
  }
  return false;
};

// 永続化はせずにテーマ設定だけ変更する
export const setInstantTheme = (t: ThemePreference): void => {
  isDark.value = checkIsDark(t);
};

export const syncThemePreference = () => {
  const t = getThemePreference();
  window.localStorage.setItem(LS_KEY, t);
  isDark.value = checkIsDark(t);
};

// この設定に対して reactive にする
export const isDark = ref(checkIsDark(getThemePreference()));
